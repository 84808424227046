<template>
    <div class="login__register">
        <div class="h2">{{ $t('labels.register') }}</div>
        <div class="text--normal">{{ $t('labels.register_text') }}</div>

        <Errors :errors="errors" @hideErrors="errors = {}"></Errors>

        <form @submit="onRegister" class="login__register__form">
            <TextField :label="$t('labels.firstname')" name="firstname" :required="true" />
            <TextField :label="$t('labels.lastname')" name="lastname" :required="true" />
            <TextField :label="$t('labels.email')" name="email" type="email" :required="true" />

            <PasswordField :label="$t('labels.password')" name="password" :required="true" />
            <PasswordField :label="$t('labels.password_confirmation')" name="password_confirmation" :required="true" />

            <div class="login__register__footer">
                <button class="btn btn-primary" type="submit">
                    {{ $t('labels.register') }}
                </button>
            </div>
        </form>
    </div>
</template>

<script lang="ts" setup>
import TextField from '@/vue/components/form/TextField.vue';
import { useForm } from 'vee-validate';
import { provide, ref } from 'vue';
import { FormContextKey } from '@/vue/keys';
import * as yup from 'yup';
import PasswordField from '@/vue/components/form/PasswordField.vue';
import axios from 'axios';
import Errors from '@/vue/elements/Errors.vue';

const form = useForm<Required<any>>({
    validationSchema: yup.object({
        firstname: yup.string().required(),
        lastname: yup.string().required(),
        email: yup.string().email().max(200).required(),
        password: yup.string().required(),
        password_confirmation: yup.string().required(),
    }),
    keepValuesOnUnmount: true,
    validateOnMount: true,
});

const errors = ref();

provide(FormContextKey, form);

const onRegister = form.handleSubmit(async (values) => {
    errors.value = {};
    const response = await axios.post<any>('/_/auth/register', values);

    if (response.data.status == 'success') {
        location.reload();
    }
    else {
        errors.value = response.data.errors;
    }
});
</script>
