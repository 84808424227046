import { AlpineComponent } from 'wly-statamic-theme-extensions';
import axios from 'axios';

export const http = axios.create({
    baseURL: '/',
    responseType: 'json',
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
        function (data, headers) {
            if (data instanceof FormData) {
                headers.setContentType('multipart/form-data');
            }

            return data;
        },
    ],
});

http.interceptors.response.use(
    (response) => response,
    (error) => {
        return Promise.reject(error.response);
    },
);

export class FormComponent extends AlpineComponent {
    public submitting: boolean = false;
    public success: boolean = false;
    public errors: any = {};
    public privacyPolicyAccepted: boolean = false;

    init() {
        this.errors = {};
        this.success = false;
        this.submitting = false;

        // @ts-ignore
        this.$nextTick(() => {
            this.$el.querySelector('.g-recaptcha') &&
                window.runReCaptchaCallback((grecaptcha) => {
                    grecaptcha.render(this.$el.querySelector('.g-recaptcha'));
                });

            this.$el.querySelector('.cf-turnstile') &&
                window.runCfTurnstileCallback((turnstile) =>
                    setTimeout(() => {
                        console.log('Render Turnstile', turnstile.render(this.$el.querySelector('.cf-turnstile')));
                    }, 1000),
                );
        });
    }

    submit(e: Event): Promise<any> {
        e.preventDefault();

        if (!this.privacyPolicyAccepted) {
            return Promise.reject();
        }

        const form = e.target as HTMLFormElement;
        const data = new FormData(form);

        this.submitting = true;

        return http
            .request({
                url: form.getAttribute('action') || '',
                method: form.getAttribute('method') || '',
                data,
            })
            .then((res) => {
                this.errors = {};
                this.success = true;
                (window as any).formSuccess = true;

                if (this.$el.dataset.redirect) {
                    location.href = this.$el.dataset.redirect;
                } else {
                    this.submitting = false;
                }
            })
            .catch((err: any) => {
                if (err.status === 500) {
                    alert('Currently the form does not work. Please contact us on a different way.');
                } else {
                    if (err.data.error) {
                        this.errors = err.data.error;
                    } else {
                        this.errors = {};
                        Object.keys(err.data.errors).forEach((key) => {
                            this.errors[key] = Array.from(err.data.errors[key]).join(', ');
                        });

                        if (this.errors?.captcha) {
                            window.runReCaptchaCallback((grecaptcha) => {
                                grecaptcha.reset(this.$el.querySelector('.g-recaptcha'));
                            });

                            window.runCfTurnstileCallback((turnstile) => {
                                turnstile.reset(this.$el.querySelector('.cf-turnstile'));
                            });
                        }
                    }
                }

                this.submitting = false;
            });
    }
}
