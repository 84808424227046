import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { User } from '@/vue/types';

export const useUserStore = defineStore('userStore', () => {
    const userSessionData = ref<Record<string, any>>({});
    const loggedIn = computed<boolean>(() => userSessionData.value?.logged_in ?? false);
    const logoutUrl = computed(() => userSessionData.value?.logout_url);
    const resetUrl = computed(() => userSessionData.value?.reset_url);
    const user = computed<User | null>(() => userSessionData.value?.user ?? null);

    const setUser = (data?: User | null) => {
        userSessionData.value.user = data || null;

        if (data) {
            userSessionData.value.logged_in = true;
        }
    };

    fetch('/!/user')
        .then((res) => res.json())
        .then((data) => {
            userSessionData.value = data;
        });

    return {
        loggedIn,
        logoutUrl,
        resetUrl,
        user,
        setUser,
    };
});
