import { updateActiveItem as updateActiveNavItem } from './navigation';
import Baguettebox from './baguette-box';
import Swiper from './swiper';
import Accordion from './accordion';
import { initAnimations } from '@/scripts/onload/animations.js';
import { Teich } from '@/scripts/onload/teich';

/**
 * Will be called each time a new page has been loaded.
 */
export const onLoad = () => {
    Baguettebox();
    Swiper();
    updateActiveNavItem();
    Accordion();
    initAnimations();
    Teich();
};
