<template>
    <div v-if="errors && Object.keys(errors).length">
		<div class="form__error__window">
			<div @click="emit('hideErrors')" class="error__window__closer">
				<IconX />
			</div>
			<div class="h4">{{ $t('labels.incorrect_data') }}</div>
            <div class="text--legend" v-for="(error, key) in errors">{{ error[0] }}</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue';

import IconX from '@/svg/icon-x-small.svg?component';

const emit = defineEmits(['hideErrors']);

defineProps({
    errors: {
        type: Object as PropType<Any|null>,
        required: false
    }
});
</script>
