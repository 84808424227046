import { CheckoutInformationFormComponent } from '@/alpine/CheckoutInformationFormComponent';
import { FormComponent } from '@/alpine/FormComponent';
import { SwiperComponent } from '@/alpine/SwiperComponent';
import { ToggleGlobalSpinnerComponent } from '@/alpine/ToggleGlobalSpinnerComponent';
import { CheckoutPaymentFormComponent } from '@/alpine/CheckoutPaymentFormComponent';
import { ProductFormComponent } from '@/alpine/ProductFormComponent';

export const AlpineComponents = {
    CheckoutInformationForm: CheckoutInformationFormComponent,
    CheckoutPaymentForm: CheckoutPaymentFormComponent,
    ProductForm: ProductFormComponent,
    // FixedHeader: FixedHeaderComponent,
    Form: FormComponent,
    Swiper: SwiperComponent,
    ToggleGlobalSpinner: ToggleGlobalSpinnerComponent,
};
