<template>
    <div v-if="!showLoginFailed">
        <div class="h4">{{ loginTitle ? loginTitle : $t('labels.login') }}</div>
        <div v-if="loginText" v-html="loginText" />

        <form @submit="onLogin" class="form--login text--middle">
            <div class="form--login__row">
                <TextField :label="titleUsername ? titleUsername : $t('labels.email')" name="email" type="email" :required="true" />
                <PasswordField :label="$t('labels.password')" name="password" type="password" :required="true" />
            </div>

            <div class="login__form__row">
                <div class="login__form__col">
                    <CheckboxField :label="$t('labels.stay_signed_in')" name="remember" />
                </div>
                <div class="login__form__col col--right">
                    <div class="login__form__actions">
                        <div @click="updateMode('resetPassword')" class="link--icon">
                            <IconArrowRightSmall />
                            {{ $t('labels.reset_password') }}
                        </div>
                        <div v-if="!hideRegister" @click="updateMode('register')" class="link--icon">
                            <IconArrowRightSmall />
                            {{ $t('labels.create_account') }}
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary">
                        <InlineSpinner v-if="form.isSubmitting.value" />
                        {{ $t('labels.login') }}
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div v-else>
        <div class="login__message login--error">
            <div class="h2">
                <IconInfo />
                {{ $t('labels.incorrect_data') }}
            </div>
            <div class="text--normal">{{ $t('labels.wrong_email_password') }}</div>

            <div class="login__error__footer text--middle">
                <div class="btn btn-primary" @click="showLoginFailed = false">
                    {{ $t('labels.try_again') }}
                </div>
                <div @click="updateMode('resetPassword')" class="link--icon">
                    <IconArrowRightSmall />
                    {{ $t('labels.reset_password') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import TextField from '@/vue/components/form/TextField.vue';
import { useForm } from 'vee-validate';
import { provide, ref } from 'vue';
import { FormContextKey } from '@/vue/keys';
import * as yup from 'yup';
import PasswordField from '@/vue/components/form/PasswordField.vue';
import CheckboxField from '@/vue/components/form/CheckboxField.vue';
import axios from 'axios';
import {useUserStore} from "@/vue/store/user";
import IconArrowRightSmall from '@/svg/icon-arrow-right-extra.svg?component';
import IconInfo from '@/svg/icon-info-filled.svg?component';
import { InlineSpinner } from 'wly-statamic-theme-extensions';

const emit = defineEmits(['update:modelValue', 'login-success']);

const props = defineProps({
     loginTitle: {
        type: String,
        required: false,
    },
    loginText: {
        type: String,
        required: false,
    },
    titleUsername: {
        type: String,
        required: false,
    },
    hideRegister: {
        type: Boolean,
        required: false,
    },
    redirectAfterLogin: {
        type: Boolean,
        required: false,
    },
});

const userStore = useUserStore();

const updateMode = (mode: string) => {
    emit('update:modelValue', mode);
};

const form = useForm<Required<any>>({
    validationSchema: yup.object({
        email: yup.string().email().max(200).required(),
        password: yup.string().required(),
    }),
    keepValuesOnUnmount: true,
    validateOnMount: true,
});

provide(FormContextKey, form);

const showLoginFailed = ref<boolean>(false);

const onLogin = form.handleSubmit(async (values) => {
    values['redirect'] = props.redirectAfterLogin ?? false;
    const response = await axios.post<any>('/_/auth/login', values);

    if (response.data.status == 'success') {
        userStore.setUser(response.data.user);
        emit('login-success');

        if(response.data.redirect) {
            window.location.href = response.data.redirect;
        }
    } else {
        showLoginFailed.value = true;
    }
});
</script>
