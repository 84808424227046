import { FormComponent } from '@/alpine/FormComponent';

export class CheckoutPaymentFormComponent extends FormComponent {
    public gateway: string = '';
    public gatewayUrl: string = '';
    public privacyPolicyAccepted: boolean = true;
    public redirect: string;

    constructor(config: { redirect: string; gateway?: string }) {
        super();

        this.redirect = config.redirect;
        this.gateway = config.gateway ?? '';
    }

    public submit(e: Event): any {
        if (this.gateway === 'invoice') {
            document.body.classList.add('show-global-spinner');

            super.submit(e).then(() => {
                document.location.href = this.redirect;
            });

            return;
        }

        if (this.gatewayUrl) {
            document.body.classList.add('show-global-spinner');
            document.location.href = this.gatewayUrl;
        }
    }
}
