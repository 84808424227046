import $ from 'jquery';

// Checks if any particular element is in viewport
$.fn.isInViewport = function () {
    const elementTop = $(this).offset().top;
    const viewportTop = $(window).scrollTop();
    const viewportBottom = viewportTop + $(window).height();

    return elementTop < viewportBottom;
};

export const initAnimations = () => {
    const elements = document.querySelectorAll('.animateblock');
    const windowHeight = window.innerHeight;

    const scrollAnimationHandler = (e = null) => {
        elements.forEach(function (el) {
            if (el.classList.contains('animated')) {
                return true;
            }

            const elOffsetBottom = (el.getBoundingClientRect().top - windowHeight) * -1;

            if ((!e && elOffsetBottom >= 0) || elOffsetBottom >= 50) {
                el.classList.add('animated');
            }
        });
    };

    scrollAnimationHandler();
    window.addEventListener('scroll', scrollAnimationHandler, { passive: true });

    // Animate numbers when scrolled into view
    $(window).scroll(function () {
        $('.numbers__content .numbers__number').each((i, el) => {
            const $counter = $(el);

            if (!$counter.isInViewport() || $counter.data('animation-started')) return;

            $counter
                .data('animation-started', true)
                .prop('Counter', 0)
                .animate(
                    {
                        Counter: $counter.text(),
                    },
                    {
                        duration: 3000,
                        easing: 'swing',
                        step: function (now) {
                            $counter.text(Math.ceil(now).toLocaleString('de-CH'));
                        },
                    },
                );
        });
    });
};
